import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'

export interface TechnicalDialogProps {
  open: boolean
  setOpen: any
}

export default function TechnicalErrorDialog(props: TechnicalDialogProps) {
  const handleClose = (event: any, reason: any) => {
    props.setOpen(false)
  }

  const handlePositive = () => {
    props.setOpen(false)
  }

  const { t } = useTranslation('translation')

  const title = t('Technischer Fehler')
  const dialog = t(
    'Leider gab es einen technischen Fehler, den wir aktuell auf den Firefox-Browser zurückführen. Bitte nutze einen alternativen Browser bis wir den Fehler behoben haben. Vielen Dank.',
  )
  const positive = 'OK'

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{dialog}</DialogContent>
        <DialogActions>
          <Button onClick={handlePositive} color="primary" autoFocus>
            {positive}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
