import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Address } from '../../types'
import {env} from '../../../environment'

export interface AddressProps {
  title: string
  address: Address
}

export default function AddressReview(props: AddressProps) {
  let address = props.address

  const addressLines = [address.first_name + ' ' + address.last_name]
  if (address.company) {
    addressLines.push(address.company)
  }
  addressLines.push(address.street + ' ' + address.housenumber)
  addressLines.push(address.zip + ' ' + address.city)
  if (env.getCountry() != address.country) {
    addressLines.push(address.country.toUpperCase())
  }

  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      {addressLines.map((addressLine: string, i) => (
        <Typography key={'address_line_' + i} gutterBottom>
          {addressLine}
        </Typography>
      ))}
    </Grid>
  )
}
