import React from 'react'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import Teaser from '../../components/artboxone/Teaser'
import { useTranslation } from 'react-i18next'

export default function Terms(props: any) {
  const [terms, setTerms] = React.useState({
    terms1: false,
    terms2: false,
    terms3: false,
  })

  const { t } = useTranslation('translation')
  const handleClick = (event: any) => {
    let et = event.target

    let id = et.name
    let value = et.checked

    trackEvent({
      category: 'checkout',
      action: 'checkbox',
      label: id,
      value: et.checked ? 'yes' : 'no',
    })

    let changedElement: any = {}
    changedElement[id] = value
    setTerms({ ...terms, ...changedElement })
  }

  props.setValid(terms.terms1 && terms.terms2)
  props.setNewsletter(terms.terms3)

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="terms1"
              value="yes"
              onClick={handleClick}
              checked={terms.terms1}
            />
          }
          label={
            t(
              'Ich habe die AGB und Widerrufsbestimmungen gelesen und stimme ausdrücklich zu.',
            ) + ' *¹'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="terms2"
              value="yes"
              onClick={handleClick}
              checked={terms.terms2}
            />
          }
          label={
            t(
              'Ich habe die Datenschutzerklärung gelesen und stimme ausdrücklich zu.',
            ) + ' *¹'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="terms3"
              value="yes"
              onClick={handleClick}
              checked={terms.terms3}
            />
          }
          label={t('Gratis Newsletter abonnieren')}
        />
      </Grid>
    </React.Fragment>
  )
}
