import React from 'react'
import InnerAddressForm from './InnerAddressForm'
import MailAddressForm from './MailAddressForm'

import { getCart } from '../../lib/cart_hook'
import {
  setInvoiceAddress as storeInvoiceAddress,
  setDeliveryAddress as storeDeliveryAddress,
  setMailaddress as storeMailAddress,
} from '../../lib/cart_hook'
import { useTranslation } from 'react-i18next'

const AddressForm = (props: any) => {
  let cart = getCart()

  const [hasInvoiceAddress, setHasInvoiceAddress] = React.useState(
    typeof cart.invoice_address !== 'undefined' ||
      (typeof cart.delivery_address !== 'undefined' &&
        typeof cart.delivery_address.street !== 'undefined' &&
        cart.delivery_address.street == 'Packstation'),
  )

  const [invoiceAddress, setInvoiceAddress] = React.useState(
    cart.invoice_address || {},
  )
  const [deliveryAddress, setDeliveryAddress] = React.useState(
    cart.delivery_address || {},
  )

  const [mailAddress, setMailAddress] = React.useState(cart.mail_address || '')

  if (!hasInvoiceAddress) {
    storeInvoiceAddress(undefined)
  }

  const [validDelivery, setValidDelivery] = React.useState(false)
  const [validInvoice, setValidInvoice] = React.useState(!hasInvoiceAddress)
  const [validMail, setValidMail] = React.useState(mailAddress != '')
  props.setValid(
    validMail && validDelivery && (!hasInvoiceAddress || validInvoice),
  )

  const { t } = useTranslation('translation')
  return (
    <>
      <MailAddressForm
        key="mail_address"
        title={t('Email-Adresse')}
        address={mailAddress}
        setAddress={setMailAddress}
        storeAddress={storeMailAddress}
        setValid={setValidMail}
        setErrorOpen={props.setErrorOpen}
      />
      <InnerAddressForm
        key="delivery_address_"
        title={t('Lieferanschrift')}
        description={<></>}
        address={deliveryAddress}
        setAddress={setDeliveryAddress}
        storeAddress={storeDeliveryAddress}
        hasAdditionalAddress={hasInvoiceAddress}
        setHasAdditionalAddress={setHasInvoiceAddress}
        showAdditionalAddressCheckbox={true}
        setAdditionalAddress={setInvoiceAddress}
        setValid={setValidDelivery}
        setErrorOpen={props.setErrorOpen}
      />
      {hasInvoiceAddress && (
        <InnerAddressForm
          key="invoice_address_"
          constraintCountry={deliveryAddress.country}
          title={t('Rechnungsanschrift')}
          description={<></>}
          address={invoiceAddress}
          setAddress={setInvoiceAddress}
          storeAddress={storeInvoiceAddress}
          showAdditionalAddressCheckbox={false}
          setValid={setValidInvoice}
          setErrorOpen={props.setErrorOpen}
        />
      )}
    </>
  )
}

export default AddressForm
