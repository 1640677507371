import {env} from '../../../environment'

export const fetchPaymentData = (options: any) => {
  let url = env.getServiceHost() + 'order/payments'
  let form = new FormData()

  form.append('country', options.country)

  if (options.voucherCode) {
    form.append('voucherCode', options.voucherCode)
  }
  if (options.hasInvoiceAddress) {
    form.append('hasInvoiceAddress', '1')
  }

  if (options.mail) {
    form.append('mail', options.mail)
  }

  let i = 0
  options.products.map((product: any) => {
    form.append('products[' + i + '][count]', product.count)
    if (typeof product.productId !== 'undefined') {
      form.append('products[' + i + '][productId]', product.productId)
    } else {
      form.append('products[' + i + '][materialIId]', product.materialIId)
    }
    i += 1
  })
  form.append('deviceType', options.deviceType)

  return fetch(url, { method: 'post', body: form })
    .then(response => response.text())
    .then(response => {
      try {
        return JSON.parse(response)
      } catch (err) {
        return {
          error: 1,
          data: '[FATAL] Fehler bei Payment API',
        }
      }
    })
}
