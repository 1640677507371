import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { validateMailaddress } from '../../utils/utils'
import fetchError from '../../lib/global/error_api'

export default function MailAddressForm(props: any) {
  const [valid, setValid] = React.useState(validateMailaddress(props.address))

  const handleChange = (event: any) => {
    let tf: any = event.target

    let m = tf.value.trim()
    props.setAddress(m)
    let valid = validateMailaddress(m)
    props.setValid(valid)

    if (valid) {
      if (!props.storeAddress(m)) {
        props.setErrorOpen(true)
      }
    }

    setValid(valid)
  }

  // const handleBlur = (event: any) => {
  //   if (!valid && props.address != '') {
  //     fetchError({
  //       data: 'Invalid Mailaddress in Checkoutform *' + props.address + '*',
  //     })
  //   }
  // }

  const { t } = useTranslation('translation')
  props.setValid(validateMailaddress(props.address))

  return (
    <React.Fragment>
      <Typography variant="h6">{props.title}</Typography>

      <Typography gutterBottom>
        {t(
          'An die Mailadresse werden Transaktions-Mails gesendet, z.B. wenn dein Auftrag unsere Produktion verlässt.',
        )}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="mail_address"
            label={t('Email-Adresse')}
            fullWidth
            autoComplete="mail"
            value={props.address}
            onChange={handleChange}
            error={!valid}
          />
        </Grid>
      </Grid>
      <Box paddingBottom={4} />
    </React.Fragment>
  )
}
