import { ProductData, ProductMaterial } from '../types'
import { useMaterialsData } from '../lib/materials_hook'
import { useProductsByIdData } from '../lib/products_by_ids_hooks'
import { isConnectedMaterial, mapMaterialToApiGroup } from './Mapping'
import { getPriceCountry, getCartCountry } from './CountrySelection'
import { getHash } from './Crypto'

export const buildSku = (material: string, motiveDbId: number) => {
  let result = ''

  if (material == 'wallcalendar') {
    return 'CAL-' + motiveDbId + 'W'
  } else if (material == 'foldingcalendar') {
    return 'CAL-' + motiveDbId + 'K'
  } else if (material == 'deskcalendar') {
    return 'CAL-' + motiveDbId + 'T'
  } else if (material == 'familyplaner') {
    return 'CAL-' + motiveDbId + 'F'
  } else if (material == 'squarecalendar') {
    return 'CAL-' + motiveDbId + 'Q'
  } else if (material == 'cups') {
    result += 'CUP'
  } else if (material == 'canvas') {
    result += 'CA'
  } else if (material == 'galleryprint') {
    result += 'GP'
  } else if (material == 'acryl') {
    result += 'AC'
  } else if (material == 'alu') {
    result += 'AL'
  } else if (material == 'wooden') {
    result += 'WO'
  } else if (material == 'puzzle') {
    result += 'PU'
  } else if (material == 'pillow') {
    result += 'PI'
  } else if (material == 'magnet') {
    result += 'MA'
  } else if (material == 'cases') {
    result += 'SC'
  } else if (material == 'advent') {
    // Änderung auf AK für Criteo https://intranet.cewe.lan/jira/browse/PXABO-5577
    result += 'AK'
  } else if (material == 'placemat') {
    result += 'TI'
  } else if (
    material == 'tshirt' ||
    material == 'sweatshirt' ||
    material == 'hoodie'
  ) {
    result += 'FA'
  } else if (material == 'poster') {
    result += 'PO'
  } else if (material == 'metalposter') {
    result += 'MP'
  } else if (material == 'poster_plastic') {
    result += 'PP'
  } else if (
    material == 'poster_wooden_frame' ||
    material == 'poster_metal_frame'
  ) {
    result += 'FR'
  } else {
    console.log('SKU for *' + material + '*?')
    result += 'PO'
  }

  result += '-MD-' + motiveDbId

  return result
}

export const getCrosslinkProducts = (
  pd: ProductData,
  currentMaterial: string,
  includeCurrentMaterial: boolean,
) => {
  const allMaterialsData = useMaterialsData()

  const materials: string[] = []

  allMaterialsData.map((material: ProductMaterial) => {
    const m = material.materialgroup

    if (
      materials.indexOf(m) === -1 &&
      pd.materialIIds?.indexOf(material.iid) !== -1 &&
      (includeCurrentMaterial || m != currentMaterial)
    ) {
      materials.push(m)
    }
  })

  const priceCountry = getPriceCountry(getCartCountry())

  let display: any = []
  const productsToFetch: any = []

  materials.map((material: string) => {
    productsToFetch.push({
      material: material,
      motiveId: '' + pd?.id,
      ratio: '' + pd?.ratio,
    })
  })

  const p: any = useProductsByIdData(productsToFetch)

  if (typeof p === 'undefined') {
    display = undefined
  } else if (typeof p == 'object' && p.length == 0) {
    display = undefined
  } else {
    materials.map((material: string) => {
      const m = allMaterialsData.filter((materialData: ProductMaterial) => {
        return (
          isConnectedMaterial(materialData, material) &&
          materialData.price[priceCountry] > 0
        )
      })

      if (typeof p !== 'undefined') {
        const mappedMaterial = mapMaterialToApiGroup(material)

        if (
          typeof p[pd?.id] !== 'undefined' &&
          typeof p[pd?.id][mappedMaterial] !== 'undefined' &&
          typeof p[pd?.id][mappedMaterial][pd.ratio] !== 'undefined'
        ) {
          const elem = {
            productData: p[pd?.id][mappedMaterial][pd?.ratio],
            materialData: m,
            material: material,
            signature: '',
          }

          if (m.length > 0) {
            if (m[0].price[priceCountry] > 0) {
              elem.signature = getHash(elem)

              display.push(elem)
            }
          }
        } else {
          // console.log('Missing material "' + mappedMaterial + '"')
        }
      }
    })
  }

  return display
}
