import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CartOverview from '../../components/cart/CartOverview'
import { FormControlLabel, Checkbox } from '@mui/material'
import AddressReview from '../../components/checkout/AddressReview'
import PaymentReview from '../../components/checkout/PaymentReview'
import { getCart } from '../../lib/cart_hook'
import Terms from './Terms'
import OneActionDialog from '../../components/generic/OneActionDialog'
import { getCartItems } from '../../lib/cartitems_hook'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-bootstrap'
import {env} from '../../../environment'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
}))

export default function Review(props: any) {
  const classes = useStyles()

  const { t } = useTranslation('translation')
  let cart = getCart()

  let cartItems = getCartItems()

  if (cartItems.length == 0) {
    navigate('/warenkorb')
  }

  let alert = <></>

  if (env.getLanguage() == 'de') {
    alert = (
      <Alert
        key="buy_alerting"
        variant="primary"
        style={{ textAlign: `center` }}
      >
        Deine Bestellung ist <strong>noch nicht</strong> abgeschickt. Bitte
        prüfe deinen Warenkorb, Lieferadresse und bestätige uns, dass du die
        AGB, Widerrufsbestimmungen und die Datenschutzerklärung zur Kenntnis
        genommen hast! Dann geht es mit dem "<strong>Jetzt Kaufen</strong>"
        Button (unten rechts) zum Auftrag!
      </Alert>
    )
  } else {
    alert = (
      <Alert
        key="buy_alerting"
        variant="primary"
        style={{ textAlign: `center` }}
      >
        Please check your shopping cart, delivery address and confirm that you
        have read the declarations! Afterwards, you can place the order at the
        bottom of this page.
      </Alert>
    )
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t('Zusammenfassung')}
      </Typography>
      {alert}
      <CartOverview review={true} />
      <Grid container spacing={2}>
        <AddressReview
          title={t('Lieferung geht an')}
          address={cart.delivery_address}
        />
        {cart.invoice_address && (
          <AddressReview
            title={t('Rechnung geht an')}
            address={cart.invoice_address}
          />
        )}

        <Grid item container direction="column" xs={12} sm={6}>
          <PaymentReview payment={cart.payment} />
        </Grid>

        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {t('Deine Bestellung abschicken')}
          </Typography>

          <Grid container>
            <Terms
              setValid={props.setValid}
              setNewsletter={props.setNewsletter}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography>*¹ {t('Pflichtfelder')}</Typography>
    </React.Fragment>
  )
}
