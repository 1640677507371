import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { LinearProgress, Button } from '@mui/material'
import { Alert } from 'react-bootstrap'
import PaymentIcon from '@mui/icons-material/Payment'
import { addOrder, setOrderPaid } from '../../lib/orders_hook'
import { useOrderData } from '../../lib/order_hook'
import { clearCartItems } from '../../lib/cartitems_hook'
import {
  pushTmEvent,
  setEnvironmentVariable,
} from '../../components/layout/EnvironmentVariables'
import Nps from '../../components/artboxone/Nps'
import { buildSku } from '../../utils/ProductUtils'
import { useTranslation } from 'react-i18next'
import {env} from '../../../environment'
import { submitCartProductsOrderTracking, trackEvent } from '../../utils/ArtboxoneTracking'
import { clearLocalStorageExtVersion } from '../../utils/Storage'
import { calculateVat, getCartCountry } from '../../utils/CountrySelection'
import { getEmailHash } from '../../utils/getEmailHash'

export default function DoOrderWithData(props: any) {
  console.log('Creating order ' + props.cart.run + '. ' + props.orderHash)

  const { t } = useTranslation('translation')
  const orderInformation: any = useOrderData({
    cart: props.cart,
  })

  const { orderData } = orderInformation
  const { priceSum } = orderData
  const vat = calculateVat(priceSum, getCartCountry())
  const priceSumNet = priceSum - vat

  const placingOrder = typeof orderInformation.result == 'undefined'
  // const { setCartCount } = useContext(SearchContext)

  if (placingOrder) {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          {t('Auftrag wird abgesendet...')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  } else if (orderInformation.result.error > 0) {
    let backToCartButton = <></>

    if (typeof orderInformation.result.resetCache !== 'undefined') {
      clearLocalStorageExtVersion()
      backToCartButton = (
        <Button variant="contained" onClick={props.handleClickCart}>
          Noch einmal zurück zum Warenkorb
        </Button>
      )
    }

    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Fehler
        </Typography>
        <Alert variant="danger">{orderInformation.result.data}</Alert>

        <Button variant="contained" color="primary" onClick={props.handleClick}>
          Wiederholen
        </Button>
        {backToCartButton}
      </React.Fragment>
    )
  } else {
    const result = orderInformation.result.data
    const orderHash = orderInformation.orderHash

    const someOrderData = {
      cartItems: orderData.cartItems,
      seenPrices: orderData.seenPrices,
      currency: orderData.currency,
      deliveryAddress: orderData.deliveryAddress,
      invoiceAddress: orderData.invoiceAddress,
    }

    console.log('Got ' + result.orderId)
    addOrder(
      result.orderId,
      orderHash,
      orderData.deliveryAddress.email,
      someOrderData,
    )

    if (orderData.payment == 'invoice') {
      setOrderPaid(orderHash)
    }

    const emailHash = getEmailHash(props.cart.cart.mail_address)

    const estimatedDeliveryDate = new Date()
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7)

    const order: any = {
      orderId: result.orderId,
      email: orderData.deliveryAddress.email,
      emailHash: emailHash,
      orderAmountGross: priceSum,
      orderAmountGrossNet: priceSumNet,
      orderBonusGross: orderData.priceBonus,
      orderShippingGross: orderData.seenPrices.shipping,
      currency: orderData.currency,
      paymentName: props.cart.cart.payment,
      estimatedDelivery:
        '' +
        estimatedDeliveryDate.getFullYear() +
        '-' +
        estimatedDeliveryDate.getMonth() +
        '-' +
        estimatedDeliveryDate.getDate(),

      items: [],
      voucher: orderData.voucher ? orderData.voucher : '',
    }

    orderData.cartItems.map((cartItem: any) => {
      order.items.push({
        name: cartItem.item.name,
        amountGross: cartItem.material.price,
        count: cartItem.count,
        motiveId: cartItem.item.id,
        materialIId: cartItem.materialIId,
        materialName: cartItem.material.materialName,
        materialVariant: cartItem.material.materialVariant,
        sku: buildSku(cartItem.material.materialName, cartItem.item.id),
      })
    })

    if (
      props.cart.cart.payment != 'testok' &&
      props.cart.cart.payment != 'testfail'
    ) {
      clearCartItems()
      // setCartCount(0)
    }

    submitCartProductsOrderTracking(order)

    trackEvent({
      category: 'checkout',
      action: 'process',
      label: 'success-direct',
    })

    return (
      <React.Fragment>
        {setEnvironmentVariable('order', order)}
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2>{t('Fein')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <Typography variant="subtitle1">
                {t('Wir haben deine Bestellanfrage unter der Auftragsnummer')}
                <strong> {result.orderId} </strong>
                {t(
                  'erhalten. Weitere Informationen und Updates gibt es per Mail!',
                )}
              </Typography>
            </div>
          </div>

          {typeof result.redirect.url === 'string' && (
            <>
              <form action={result.redirect.url} id="payment" method="post">
                {Object.entries(result.redirect.params).map(([key, value]) => (
                  <input type="hidden" key={key} name={key} value={value} />
                ))}
              </form>
              <div className="row">
                <div className="col text-center">
                  <h2>{t('Bezahlung')}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <p>{t('Bitte führe noch die Bezahlung aus!')}</p>
                  <Button
                    data-test="payment_button"
                    variant="contained"
                    color="primary"
                    onClick={() => document.getElementById('payment').submit()}
                  >
                    <PaymentIcon /> {t('Jetzt bezahlen')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        {pushTmEvent('artboxone-order-success')}

        {env.getLanguage() == 'de' && (
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div id="TrustedShopsReviews">&nbsp;</div>
              </div>
            </div>
          </div>
        )}

        {env.getLanguage() == 'de' && <Nps orderId={result.orderId} />}
      </React.Fragment>
    )
  }
}
