import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export interface DialogProps {
  open: boolean
  setOpen: any
  title: string
  dialog: any
  positive: string
  positiveAction: any
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
}

export default function OneActionDialog(props: DialogProps) {
  const handlePositive = () => {
    props.positiveAction()
  }

  const handleClose = (event: any, reason: any) => {
    if (props.disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (props.disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    props.setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>{props.dialog}</DialogContent>
        <DialogActions>
          <Button onClick={handlePositive} color="primary" autoFocus>
            {props.positive}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
