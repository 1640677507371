import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { getCartPaymentData, setCartPaymentData } from '../../lib/cart_hook'

export const validate = (paymentName: string, paymentNumber: string) => {
  let errors: any = []

  if (paymentName.length < 2) {
    errors['paymentName'] = true
  }

  if (paymentNumber.substr(0, 2) != 'DE') {
    errors['paymentNumber'] = true
  }

  if (paymentNumber.length != 22) {
    errors['paymentNumber'] = true
  }

  if (
    !/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
      paymentNumber,
    )
  ) {
    errors['paymentNumber'] = true
  }

  return errors
}

export const cleanupPaymentNumber = (paymentNumber: string) => {
  let result = paymentNumber.match(new RegExp('.{1,4}', 'g'))
  return result !== null ? result.join(' ') : ''
}

export default function PaymentFormSepa(props: any) {
  const [paymentData, setPaymentData] = React.useState(getCartPaymentData())
  let visiblePaymentNumber = cleanupPaymentNumber(paymentData.paymentNumber)
  let errors: any = validate(paymentData.paymentName, paymentData.paymentNumber)

  let errorKeys = Object.keys(errors)
  props.setValid(errorKeys.length == 0)

  const handleChange = (event: any) => {
    let tf: any = event.target

    let newData: any = {
      paymentName: paymentData.paymentName,
      paymentNumber: paymentData.paymentNumber,
    }

    let value = tf.value.toUpperCase()

    if (tf.name == 'paymentNumber') {
      if (value.length > 0) {
        value = value.split(' ').join('').trim()
      }
    }

    newData[tf.name] = value

    setPaymentData(newData)
    if (setCartPaymentData(newData.paymentName, newData.paymentNumber)) {
      visiblePaymentNumber = cleanupPaymentNumber(newData.paymentNumber)
    } else {
      props.setErrorOpen(true)
    }
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name="paymentName"
          label="Kontoinhaber"
          value={paymentData.paymentName}
          fullWidth
          onChange={handleChange}
          error={errorKeys.indexOf('paymentName') >= 0}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name="paymentNumber"
          label="IBAN (DE...)"
          value={visiblePaymentNumber}
          fullWidth
          onChange={handleChange}
          error={errorKeys.indexOf('paymentNumber') >= 0}
        />
      </Grid>
    </>
  )
}
