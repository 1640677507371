import React from 'react'
import Grid from '@mui/material/Grid'
import { getCart } from '../../lib/cart_hook'
import { getPaymentOptions } from '../../utils/PaymentSelection'
import { getValidCountry } from '../../utils/CountrySelection'
import { getCartItems } from '../../lib/cartitems_hook'
import PaymentFormInner from './PaymentFormInner'
import { LinearProgress } from '@mui/material'

export default function PaymentForm(props: any) {
  let cart = getCart()
  let hasInvoiceAddress = typeof cart.invoice_address !== 'undefined'
  let country = getValidCountry(cart.delivery_address.country)
  let mailAddress = cart.mail_address

  let options = getPaymentOptions(
    country,
    hasInvoiceAddress,
    getCartItems(),
    cart.voucher_code,
    mailAddress,
  )

  if (options.length == 0) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  } else {
    return (
      <PaymentFormInner
        setValid={props.setValid}
        options={options}
        setErrorOpen={props.setErrorOpen}
      />
    )
  }
}
